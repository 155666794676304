import ElementCard from '../../../../cards/ElementCard';
import useModals from '../../../../../hooks/useModals';
import ElementsWrapper from '../../tabs/ElementsWrapper';

function Popup(): JSX.Element {
    const { setPopupModal, setFeatureModal } = useModals();

    const s3Url = (type: number): string => `${import.meta.env.VITE_S3_PROD_BASE_URL}/popups/${type}.png`;

    const onSelect = (): void => {
        setFeatureModal({ open: false });
        setTimeout(() => {
            setPopupModal({ open: true, popup: null });
        });
    };

    return (
        <ElementsWrapper>
            <ElementCard title="1" url={s3Url(1)} isActive onClick={onSelect} />
        </ElementsWrapper>
    );
}

export default Popup;
