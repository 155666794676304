import { Button, Switch, Table } from 'antd';
import { DeleteOutlined, EditOutlined, EyeFilled, EyeInvisibleFilled, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import dayjs from 'dayjs';
import collect from 'collect.js';
import PopupLayout from '../../layouts/PopupLayout';
import PopupManager from '../../../services/api/PopupManager';
import ActionConfirmation from '../../popover/ActionConfirmation';
import usePopup from '../../../hooks/usePopup';
import useRdxStore from '../../../hooks/useRdxStore';
import useModals from '../../../hooks/useModals';
import usePages from '../../../hooks/usePages';

function PopUpsForm(): JSX.Element {
    const { store } = useRdxStore();

    const { visiblePopup } = usePopup();

    const { setPopupModal } = useModals();

    const { navigationItems } = usePages();

    const [deletingPopupId, setDeletingPopupId] = useState<number | null>(null);

    const [deleteClickedId, setDeleteCLickedId] = useState<number | null>(null);

    const toggleVisibility = (checked: boolean, id: number): void => {
        PopupManager.put(id, {
            is_hidden: checked,
        });
    };

    const deletePopup = async (id: number): Promise<void> => {
        setDeletingPopupId(id);
        await PopupManager.delete(id);
        setDeletingPopupId(null);
    };

    return (
        <PopupLayout
            title="Popups"
            rightContent={
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setPopupModal({ open: true, popup: null })}
                >
                    Add Popup
                </Button>
            }
        >
            <Table
                columns={[
                    {
                        title: 'Image',
                        dataIndex: 'image',
                        render: (image) => (
                            <div className="w-[50px] h-[50px] relative border border-solid border-brand-inkGrey-grey_2 rounded-md bg-background-inkWhite-white_0 shadow">
                                <img src={image?.url ?? ''} alt="product" className="h-full w-full object-cover" />
                            </div>
                        ),
                    },
                    {
                        title: 'Page',
                        dataIndex: 'page_id',
                        render: (value) => collect(navigationItems)?.firstWhere('id', value)?.name ?? 'All',
                    },
                    {
                        title: 'Title',
                        dataIndex: 'title',
                    },
                    {
                        title: 'Description',
                        dataIndex: 'description',
                    },
                    {
                        title: 'Start Date',
                        dataIndex: 'start_date',
                        render: (value) => value && dayjs(value)?.format('DD/MM/YYYY'),
                    },
                    {
                        title: 'Expiration Date',
                        dataIndex: 'expiration_date',
                        render: (value) => value && dayjs(value)?.format('DD/MM/YYYY'),
                    },
                    {
                        title: 'Activated',
                        render: (popup) => (
                            <Switch
                                defaultChecked={!popup?.is_hidden}
                                onChange={(checked) => toggleVisibility(!checked, popup?.id)}
                            />
                        ),
                    },
                    {
                        title: 'Edit',
                        render: (popup) => (
                            <Button onClick={() => setPopupModal({ open: true, popup })}>
                                <EditOutlined />
                            </Button>
                        ),
                    },
                    {
                        title: 'Delete',
                        render: (popup) => (
                            <ActionConfirmation
                                open={deleteClickedId === popup?.id}
                                onOpenChange={() => setDeleteCLickedId(null)}
                                onConfirm={() => deletePopup(popup?.id)}
                                onCancel={() => setDeleteCLickedId(null)}
                            >
                                <Button
                                    onClick={() => setDeleteCLickedId(popup?.id)}
                                    loading={deletingPopupId === popup?.id}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </ActionConfirmation>
                        ),
                    },
                    {
                        title: 'Status',
                        render: (popup) =>
                            popup?.id === visiblePopup?.id ? (
                                <h6>
                                    <EyeFilled className="text-brand-success" />
                                </h6>
                            ) : (
                                <h6>
                                    <EyeInvisibleFilled />
                                </h6>
                            ),
                    },
                ]}
                dataSource={store?.popups || []}
                className="w-full overscroll-x-auto overflow-scroll"
                key={store?.popups?.length?.toString()}
            />
        </PopupLayout>
    );
}

export default PopUpsForm;
