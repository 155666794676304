import { useMemo, useState } from 'react';
import AppModalContext from '../context/AppModalContext';
import {
    FeatureModalProps,
    NavigationItemsModalProps,
    PopupModalProps,
    SectionModalProps,
} from '../exports/Interfaces';

interface Props {
    children: JSX.Element;
}

function AppModalProvider(props: Props): JSX.Element {
    const { children } = props;

    const [navigationItemModal, setNavigationItemModal] = useState<NavigationItemsModalProps>({
        open: false,
    });

    const [popupModal, setPopupModal] = useState<PopupModalProps>({
        open: false,
    });

    const [sectionModal, setSectionModal] = useState<SectionModalProps>({
        open: false,
    });

    const [featureModal, setFeatureModal] = useState<FeatureModalProps>({
        open: false,
    });

    const context = useMemo(
        () => ({
            navigationItemModal,
            popupModal,
            sectionModal,
            featureModal,
            setNavigationItemModal,
            setPopupModal,
            setSectionModal,
            setFeatureModal,
        }),
        [navigationItemModal, popupModal, sectionModal, featureModal],
    );

    return <AppModalContext.Provider value={context}>{children}</AppModalContext.Provider>;
}

export default AppModalProvider;
