import { useLayoutEffect, useMemo, useState, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { EditOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import collect from 'collect.js';
import Sidebar from '../navigation/Sidebar';
import Header from '../navigation/Header';
import useScreenType from '../../hooks/useScreenType';
import MobileSidebar from '../navigation/MobileSidebar';
import useRdxStore from '../../hooks/useRdxStore';
import { PAGES, SUB_PAGES } from '../../exports/Enums';
import useModals from '../../hooks/useModals';
import NavigationItemsModal from '../modals/NavigationItemsModal';
import PopupModal from '../modals/PopupModal';
import useLocationChange from '../../hooks/useRouteChange';
import EnvironmentBanner from '../environment/EnvironmentBanner';
import TemplatesForm from '../theme/templates/TemplatesForm';

function AppLayout(): JSX.Element {
    const { isDesktop } = useScreenType();

    const { store, appStatus } = useRdxStore();

    const { time, pathname } = useLocationChange();

    const { navigationItemModal, setNavigationItemModal, popupModal } = useModals();

    const navigate = useNavigate();

    const sideBarRef = document.getElementById('sidebar');

    const headerRef = useRef<HTMLDivElement>(null);

    const sideBarWidth = sideBarRef?.getBoundingClientRect()?.width ?? 0;

    const mainContentWidth = window.innerWidth - sideBarWidth;

    const [mounted, setMounted] = useState<boolean>(false);

    useLayoutEffect(() => {
        setMounted(true);
    }, []);

    const renderNavigationItemsModal = useMemo(() => <NavigationItemsModal />, [navigationItemModal]);

    const renderPopupModal = useMemo(() => popupModal?.open && <PopupModal />, [popupModal]);

    const renderNavigationItems = useMemo(
        () =>
            pathname.includes(PAGES.LAYOUT) && (
                <div className="flex items-center px-small py-small bg-brand-primary/10 overflow-x-scroll sticky z-50 w-screen">
                    <Button
                        icon={<UnorderedListOutlined />}
                        className="mr-small"
                        onClick={() => setNavigationItemModal({ open: true })}
                    />
                    <Button
                        icon={<EditOutlined />}
                        className="mr-small"
                        onClick={() => navigate(`${PAGES.THEME}${SUB_PAGES.NAVIGATION_BAR}`)}
                    />
                    <div className="flex items-center gap-4">
                        {
                            collect(store?.navigation_items)
                                .where('is_hidden', false)
                                .map((item) => (
                                    <div className="px-small py-mini rounded-full">
                                        <h6 className="mini semibold">{item.name}</h6>
                                    </div>
                                ))
                                .toArray() as JSX.Element[]
                        }
                    </div>
                </div>
            ),
        [store?.navigation_items, time, pathname],
    );

    const renderTemplates = useMemo(
        () =>
            appStatus?.showTemplates && (
                <div className="fixed inset-0" style={{ zIndex: 1000 }}>
                    <TemplatesForm />
                </div>
            ),
        [appStatus?.showTemplates],
    );

    function renderModals(): JSX.Element {
        return (
            <>
                {renderNavigationItemsModal}
                {renderPopupModal}
                {renderTemplates}
            </>
        );
    }

    if (isDesktop) {
        return (
            <>
                <div className="h-full w-screen flex">
                    <div id="sidebar" className="h-screen overscroll-contain fixed top-0">
                        <Sidebar />
                    </div>
                    <div className="h-full w-full" id="details" style={{ marginLeft: sideBarWidth }}>
                        <div
                            ref={headerRef}
                            id="header"
                            className="w-full fixed top-0 z-50"
                            style={{ width: mainContentWidth }}
                        >
                            <EnvironmentBanner />
                            <Header />
                            {renderNavigationItems}
                        </div>
                        {mounted && (
                            <div
                                className="h-full"
                                style={{
                                    marginTop: headerRef.current?.getBoundingClientRect()?.height ?? 0,
                                    width: mainContentWidth,
                                }}
                                key={time}
                            >
                                <Outlet />
                            </div>
                        )}
                    </div>
                </div>
                {renderModals()}
            </>
        );
    }

    return (
        <>
            <div className="h-full w-screen flex relative">
                <MobileSidebar />
                <div className="h-full w-full relative" id="details">
                    <EnvironmentBanner />
                    <Outlet />
                </div>
            </div>
            {renderModals()}
        </>
    );
}

export default AppLayout;
